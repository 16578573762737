import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';
import { customPropTypes } from '../../util';

import CollapsibleGrid from '../CollapsibleGrid/CollapsibleGrid';

import './FAQ.scss';
import { useCSSMotion, useSectionTimeSpent } from '../../util';
import { graphql } from 'gatsby';

function FAQ({ data: { title, questionList, showMoreText, name, id } }) {
  const containerRef = useRef(null);
  const faqMotionState = useCSSMotion(containerRef);

  useSectionTimeSpent(name, containerRef);

  return (
    <div id={id} className={classnames('FAQ', faqMotionState)} ref={containerRef}>
      {title && <h3 className="title">{title}</h3>}
      <CollapsibleGrid
        theme="faq"
        items={questionList}
        itemsToShow={4}
        showMoreText={showMoreText}
        motionState={faqMotionState}
        name={name}
      />
    </div>
  );
}

FAQ.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    questionList: CollapsibleGrid.type.propTypes.items,
    showMoreText: PropTypes.string,
  }).isRequired,
});

FAQ.defaultProps = {};

export default memo(FAQ);

export const fragments = graphql`
  fragment Faq on WpPage_Flexiblelayout_FlexibleChildren_Faq {
    className
    questionList {
      description
      title
    }
    showMoreText
    title
    id
    jumpToLinkTitle
    name
  }

  fragment Faq_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_Faq {
    className
    questionList {
      description
      title
    }
    showMoreText
    title
    id
    jumpToLinkTitle
    name
  }
`;
